.footer-container {
  position: relative;
  z-index: 1000;
  margin-top: -20px;
  background: #fff;
  padding-top: 12px;
}

.footer-border {
  overflow-x: hidden;
  background: url("../img/border-torn-paper.svg");
  background-repeat: repeat-x;
  height: 20px;
}

.footer-left {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
  }

  .footer-right {
    display: flex;
    justify-content: space-around;
    width: 200px;
    margin-top: -15px;
  }
  
  .footer-text {
    font-size: 12px;
    margin-left: 16px;
    padding-bottom: 16px;
  }

  #footer-logo {
    height: 20px;
    margin-top: -12px;
  }

  .footer-wrapper {
    position: relative;
    height: 48px;
    width: 100%;  
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer-row {
    font-family: 'Muli', sans-serif;
    font-size: 12px;
    line-height: 3.25;
    letter-spacing: -0.1px;
    color: #7A7A7A;
  }
  
  .footer-right span {
    font-family: 'Muli', sans-serif;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    line-height: 3.25;
    letter-spacing: -0.1px;
    color: #183041;
    cursor: pointer;
  }
  
  .footer-right span:hover {
    color: #FF974C;
  }

  @media screen and (max-width: 767px) {
    .footer-container.landing-footer {
      margin-bottom: 80px;
    }
  }