.hide {
    display: none !important;
}

.relative {
    position: relative;
}

.selected {
    background: #1F90FE;
    color: #fff;
}

.selected > * {
    color: #fff;
}

.width-50 {
    flex: 0 0 50%;
    max-width: 50%;
}

.space-bottom {
    margin-bottom: 20px;
}

.space-bottom-small {
    margin-bottom: 8px;
}

.space-bottom.lg {
    margin-bottom: 60px;
}

.space-top {
    margin-top: 20px;
}

.bold {
    font-weight: 700;
}

.z-1000 {
    z-index: 1100 !important;
}

.padding-right-0 {
    padding-right: 0;
}

.padding-left-0 {
    padding-left: 0;
}

.rounded {
    border-radius: 8px !important;
}

.red {
    color: red;
}

.pointer {
    cursor: pointer;
}

.gold {
    color: #FFCD6A !important;
}

.orange {
    color: #FF974C !important;
}

.green {
    color: #49CB93;
}

.strike {
    text-decoration: line-through; 
}

.text-small {
    font-size: 10px;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}
.no-margin-bottom {
    margin-bottom: 0 !important;
}

@media screen and (max-width: 767px) {
    .mobile-reverse {
        display: flex;
        flex-direction: column-reverse;
    }
}