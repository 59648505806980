.landing {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
}

#landing-container {
  width: 100vw;
  max-width: 100vw !important;
  margin: 0;
  padding: 0 !important;
}

#landing-container > .row {
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
}
.landing-section-container {
  border-top: 1px solid #EDEDED;
  min-height: 300px;
}

.landing-detail-wrapper {
  margin-bottom: 32px;
}

.landing-section-container.fff {
  background: #fff;
}

.landing-section {
  padding-bottom: 44px;
  padding-top: 44px;
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
}



.landing-content {
  margin-bottom: 52px !important;
}

h2 {
  font-family: "Danley", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 52px !important;
  line-height: 130%;
}

h4 {
  text-transform: none !important;
  font-size: 22px;
  line-height: 120%;
}

.landing-blur {
  filter: blur(2px)
}

.landing-hiw-description {
  letter-spacing: -0.01em;
}

.landing-who-we-are {
  letter-spacing: -0.01em;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.landing-hiw-img {
  margin-bottom: 20px;
}

.landing-cluster-header {
  margin-bottom: 28px !important;
}

.landing-cluster-img {
  margin-bottom: 40px;
}

#landing-laundry-img {
  position: relative;
  margin-bottom: 192px;
}

#landing-laundry-img > img {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

#landing-soon-img {
  z-index: 12;
}

.landing-preferences-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.landing-preference {
  text-align: center;
}

.landing-section-header,
.landing-subsection-header {
    font-size: 38px;
}

.landing-section-header {
  font-size: 48px;
  margin-top: 80px;
  margin-bottom: 0px;
}

.landing-subsection-header {
  margin-top: 52px;
  margin-bottom:12px;
}

.landing-copy {
  margin: 0 !important;
}

.landing-section-detail {
  font-size: 26px;
  line-height: 200%;
  letter-spacing: -0.01em;
  text-align:center;
  color: #183041;

  opacity: 0.8;
}

.landing-main-header {
    font-family: 'Danley', serif;
    font-size: 62px;
    text-align: center;
}

.landing-top-section {
  margin: 0 !important;
  position: relative;
  height: 500px;
  max-width: 1110px;
}

.landing-top-section > .row {
  width: 100%;
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
}

.landing-bottom-cta {
  margin: 42px;
}

#landing-instock {
  text-transform: none;
  margin-top: -40px;
  font-weight: bold;
  margin-bottom: 40px;
}

button.landing-get-started:disabled,
button.landing-get-started:disabled:hover {
  background-color: #49CB93;
  opacity: 0.5;
  cursor: default;
}

button.landing-get-started {
  height: 74px;
  background-color: #49CB93;
  color: #fff;
  width: 256px;
  border-radius: 6px;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

button.landing-get-started:hover {
  background-color: #31af79;
}

.landing-bottom-header {
  font-weight: bold;
  font-size: 22px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  margin: 30px auto;
}

.landing-how-text {
  font-family: 'Danley', serif;
  font-size: 20px;
  text-align: center;
}

.landing-video-container {
   position: absolute;
   width: 100%;
   left: 0;
   top: 0;
   display: flex;
   flex-direction: row;
   justify-content: center;
}

#landing-video {
  height: 500px;
  border-radius: 1px;
  width: 100%;
  z-index: 4;
  object-fit: cover;
}

.video-overlay {
  z-index: 5;
  height: 500px;
  width: 100%;
  position: absolute;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), linear-gradient(180deg, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0) 100%);
}

.landing-top-content {
  position: relative;
  z-index: 10;
}

.landing-img {
  margin-top: 60px;
  text-align: center;
}

.landing-img > img {
  max-width: 100%;
}

#hero-img {
  height: 500px;
  width: 100%;
  background-image: url('../img/hero-poster-image.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-faq-container {
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}

.landing-faq > h4 {
  margin: 30px 0 15px 0 !important;
}

.landing-faq > div {
  letter-spacing: -0.01em;
}

#landing-faq-header {
  margin-bottom: 28px !important;
}

#landing-testimonial-container {
  min-height: 345px;
}

#landing-testimonial-carousel {
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.carousel-indicators {
  bottom: -40px;
}

.carousel-indicators > li {
  width: 10px;
  height: 10px;
  border: 1px solid rgba(24, 48, 65, 0.8);
  border-radius: 50px;
}

.carousel-indicators > li.active {
  background-color: rgba(24, 48, 65, 0.8);
}

.landing-emoji {
  height: 25px;
  width: auto;
}

#landing-products-carousel {
  margin: 40px auto;
  max-width: 1110px;
}

#landing-preference-section {
  background: url('../img/bg-personalized-to-your-preferences.jpg') #ffffff;
  background-size: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.products-carousel-item {
  text-align: center;
}

.products-carousel-item > img{
  height: 120px;
  width: auto;
  cursor: pointer;
}

.react-multiple-carousel__arrow {
  background: transparent !important;
}

.react-multiple-carousel__arrow::before {
  color: rgba(24, 48, 65, 0.8) !important;
  font-weight: bold;
}

.react-multiple-carousel__arrow--right {
  margin-right: -36px;
}

.react-multiple-carousel__arrow--left {
  margin-left: -36px;
}

.input-sublink-container {
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding-left: 4px;
}

.input-sublink {
  font-size: 12px !important;
  color: #1F90FE !important;
  cursor: pointer;
}

.input-sublink:hover {
  text-decoration: underline !important;
}

.landing-apt-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.landing-apt-input-container > input {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 20px;
  background: #FFFFFF;
  border: 1px solid rgba(214, 214, 214, 0.8);
  box-sizing: border-box;
  border-radius: 8px;
  width: 30%;
  text-align: center;
  height: 50px;
}

.gate-header {
  margin-top: 20px;
  margin-bottom: 0 !important; 
}

.btn-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

.btn-blue {
  background: #1F90FE;
  border-radius: 50px;
  height: 43px;
  border: 2px solid #1F90FE;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  width: 220px;
  text-align: center;
  cursor: pointer;
  margin-top: 16px;

  color: #F9F9F9;
}

.btn-blue.btn-secondary {
  height: 75px;
  font-size: 24px;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-blue:hover {
  background: #177ad9;
  border: 2px solid #177ad9;
}

.img-404-tp {
  height: 240px;
  width: auto;
}

#btn-404 {
  width: 240px;
}

@media screen and (max-width: 1200px) {
  .landing-section,
  .landing-top-section,
  .landing-top-section > .row,
  #landing-products-carousel {
    max-width: 900px;
  }
}

@media screen and (max-width: 991px) {
    .landing-section,
    #landing-products-carousel {
      max-width: 80%;
    }
    .react-multiple-carousel__arrow--right {
      margin-right: -16px;
    }
    
    .react-multiple-carousel__arrow--left {
      margin-left: -16px;
    }
    .landing-preferences-row {
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
    }
    .landing-preference {
      margin-bottom: 30px;
    }
    .landing-main-header {
      font-size: 36px;
    }
    .landing-section-detail {
      font-size: 18px;
    }
    .landing-section-detail.apt {
      font-size: 15px;
    }
    .landing-detail-wrapper.apt {
      margin-bottom: 0;
    }
    .landing-apt-input-container > input{
      margin-bottom: 8px;
    }
    button.landing-get-started {
      height: 60px;
    }
    .landing-section-header {
      font-size: 34px;
    }
    .landing-subsection-header {
      font-size: 24px;
    }
    #landing-img-desktop {
      display: none;
    }
    .landing-top-section.mobile-small,
    .video-overlay.mobile-small,
    .landing-video-container.mobile-small,
    #landing-video.mobile-small,
    #hero-img.mobile-small {
      height: 450px;
    }
    h2 {
      font-size: 28px !important;
    }
    h4 {
      font-size: 20px !important;
    }
    .landing-section-detail {
      line-height: 140%;
    }
    .landing-top-section > .row {
      margin-top: 0px;
    }
    .landing-apt-input-container {
      flex-direction: column;
    }
    .landing-apt-input-container > input {
      width: 250px;
      margin-left: auto;
      margin-right: auto;
    }
}

@media screen and (min-width: 992px) {
  #landing-img-mobile {
    display: none;
  }
}