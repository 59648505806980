.checkout-body {
    display: flex;
    flex-direction: row;
    max-width: 95vw;
    width: 920px;
    margin-left: auto; 
    margin-right: auto;
    background: #fff;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829), 0px 15px 35px rgba(43, 64, 78, 0.095505);
    border-radius: 8px;
    padding: 28px;
    margin-top: 20px;
}

.checkout-container {
    padding-bottom: 90px;
}

.StripeElement {
    border: 1px solid #CCC;
    height: 39px;
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 4px;
}

#terms-checkbox {
    width: 20px !important;
    box-shadow: none !important;
    border-width: 0px !important;
  }
  #terms-checkbox:focus {
    box-shadow: none;
    border-width: 0px;
  
  }
  
  .validation-message {
    color: #dc3545;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 4px;
  }
  
  .checkout-confirm-details > div{
    margin-bottom: 12px;
  }
  
  .checkout-confirm-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 1.5rem 1vw 0 1vw;
    margin: 0;
    color: #4D4E4F;
  }

  .terms-text {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #183041;
    padding-left: 10px;
    padding-top: 2px;
  }

#terms-link {
    color: #1F90FE;
    cursor: pointer;
}

#terms-link:hover {
    color: #4D4048;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.checkout-form{
    margin-top: 80px;
}

#checkout-header{
    font-size: 32px;
    margin-top: 12px;
    margin-bottom: 0px;
}

.checkout-field-wrapper{
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 100%;
  margin-top: 20px;
}

.checkout-input-wrapper{
  display: flex;
  flex-direction: row;
}

.checkout-input, .invalid-input{
  height: 30px;
  border-radius: 4px;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
}

.checkout-input {
    border: 1px solid #979797;
    border-bottom: 1px solid #d3d3d3;
}

.checkout-invalid-input {
    border: 1px solid #dc3545;
    border-bottom: 1px solid #E17A84;
}

.checkout-label {
    margin-bottom: 4px;
}

.checkout-label {
    font-size: 11px;
}

.mobile-note {
    color: #465967;
    margin-top: 4px;
    max-width: 340px;
    font-size: 11px;
    font-style: italic;
}

.checkout-col-right{
    margin-left: 4px;
}

.checkout-col-left{
    margin-right: 4px;
}

.checkout-form-header {
    color: #00ABC8;
    margin-top: 24px;
    margin-bottom: -12px;
    text-align: left;
}

.checkout-address-checkbox {
    margin-left: 8px;
}

#checkout-address-label {
    margin-bottom: -12px;
}

.checkout-incorrect-credentials {
    font-size: 11px;
    margin-top: 20px;
}

.cc-note {
    font-size: 12px;
    padding-left: 12px;
    margin-top: -12px;
}

.form-group.confirmation {
    border: 1px solid #CCC;
}

#credit-card {
    padding: 1rem 0 2rem 0;
}

.checkout-total {
    float: right;
    color: #1F90FE;
}

.checkout-summary-header {
    font-size: 14px;
    cursor: pointer;
}

.checkout-summary-header-text {
    padding-right: 12px;
}

.checkout-summary-chevron {
    font: 12px !important;
    opacity: 0.6;
}

.checkout-total-text,
.checkout-total-price {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: right;

    color: #183041;
}

.checkout-total-price {
    font-size: 28px;
}

.checkout-page-header, .confirmation-details-header, .summary-details-header {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Muli', sans-serif;
    font-family: 'Muli', sans-serif;
}

.summary-details-header {
    margin-bottom: 12px;
}

.stripe-form {
    padding: 0 15px;
}

.stripe-input {
    border-radius: 8px !important;
}

.stripe-input,
input#full-name,
input#name,
textarea#delivery-instructions,
textarea#card-input,
input#modal-line1 {
    width: 100% !important;
}

select#modal-state {
    width: 124px;
}

input#modal-city {
    width: 170px;
}

textarea#delivery-instructions {
    height: 120px !important;
    resize: none;
    padding: 8px;
}

.summary-product-description, .summary-product-price {
    font-size: 14px;
}

.summary-product-breakdown {
    font-size: 12px;
    opacity: 0.6;
}

.confirmation-details-header {
    margin-bottom: -4px;
}

.summary-total-row {
    color: #1F90FE;
}
.summary-total-text {
    font-size: 18px;
    padding-top: 8px;
}
.summary-total-price {
    font-size: 28px;
}

.summary-subtotal-row {
    margin-bottom: 12px;
    font-size: 14px;
}

.loading-parachute {
    width: 100%;
    height: auto;
}

.checkout-continue-button {
    margin-left: 15px !important;
    margin-right: 15px !important;
    width: 100% !important;
}

.checkout-back-arrow {
    margin-left: 20px;
}

.signin-input {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding-left: 4px;
}

.signin-input-container {
    margin: 30px 0;
}

.checkout-btn {
    background-color: #1F90FE;
    color: #fff;
}

.checkout-error {
    margin-top: 40px;
}

.btn-secondary.dialog-btn-ok {
    border-radius: 6px 0 0 6px;
}

.btn-primary.dialog-btn-ok {
    border-radius: 0 6px 6px 0;
}

.completing-copy {
    font-family: 'Muli', sans-serif;
    font-style: italic;
}

.checkout-required {
    font-size: 12px;
    padding-left: 12px;
    margin-bottom: -8px;
}

#request-code {
    color: #1F90FE;
    font-size: 14px;
    cursor: pointer;
}

#request-code:hover {
    text-decoration: underline;
}

.checkout-stage-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.checkout-stage-number {
    border: 2px solid #548BC5;
    border-radius: 25px;
    height: 30px;
    width: 30px;
    text-align: center;
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    padding-top: 2px;

    color: #548BC5;
}

.checkout-stage-name,
#confirmation-header {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    letter-spacing: -0.16px;

    color: #1D2C3C;
    padding-left: 10px;
    padding-top: 4px;
}

#confirmation-header {
    padding: 0 !important;
    margin-bottom: 12px;
}

#confirmation-main > .checkout-stage-container {
    margin-bottom: 12px;
    margin-top: 24px;
}

.desktop-cart-summary {
    width: 369px;
    border-left: 1px solid #DFE0EB;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829), 0px 15px 35px rgba(43, 64, 78, 0.095505);
    border-radius: 9px;
    margin-top: -28px;
    margin-bottom: -28px;
    margin-right: -28px;
    padding: 28px 15px;
}

.cart-item {
    padding-top: 4px;
    padding-bottom: 4px;
}

.cart-items-container>div:nth-child(even) {
    background-color: #FAFBFE;
}

.checkout-main > .dashboard-save {
    margin-top: 40px;
    margin-bottom: 40px;
}

input#first-name,
input#last-name {
    width: 200px;
}

input#email,
input#password {
    width: 270px;
}

input#line1 {
    width: 275px;
}

input#phone,
input#line2 {
    width: 130px;
}

input#city,
select#state {
    width: 150px;
}

input#zipcode {
    width: 100px;
}

@media screen and (min-width: 992px) {
    .checkout-main {
        width: 551px;
        padding-right: 28px;
    }
}

@media screen and (max-width: 991px) {
    .cart-summary {
        width: 100%;
    }
    .checkout-main {
        width: 100%;
    }
    .checkout-body {
        width: 600px;
    }
}