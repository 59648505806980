@font-face {
  font-family: 'Danley';
  src: url('../fonts/Danley.woff2') format('woff2'),
       url('../fonts/Danley.woff') format('woff'),
       url('../fonts/Danley.otf') format('opentype')
}

@font-face {
  font-family: 'Danley-ExtraBold';
  src: url('../fonts/Danley-ExtraBold.woff2') format('woff2'),
       url('../fonts/Danley-ExtraBold.woff') format('woff'),
       url('../fonts/Danley-ExtraBold.otf') format('opentype')
}

@font-face {
  font-family: 'Danley-Bold';
  src: url('../fonts/Danley-Bold.woff2') format('woff2'),
       url('../fonts/Danley-Bold.woff') format('woff'),
       url('../fonts/Danley-Bold.otf') format('opentype')
}

body {
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  background-color: #fff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  color: #183041;
}

body.countdown-margin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

input.readonly:focus,
input.readonly {
  outline: none;
}

#cloud-intro{
  position: relative;
  min-height: 100vh;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.93), rgba(255, 255, 255, 0.93)), url(https://www.dropbox.com/s/h2bxnspkz4xjoue/clouds1000-sd.png?raw=1), url(https://www.dropbox.com/s/rth7uhc1xvh5qba/clouds1000-blur-sd-3.png?raw=1) #F3F3F3;
  opacity: 0.95;
      /* url(https://www.dropbox.com/s/rth7uhc1xvh5qba/clouds1000-blur-sd-3.png?raw=1); */
    
	animation: wind 240s linear infinite;
}
@keyframes wind{
  0% {
    background-position: 0 200px, 0 300px, 100px 250px;
  }
  100% {
    background-position: 1000px 200px, 1200px 300px, 1100px 250px;
  }

}

h2.small-margin {
  margin-bottom: 20px !important;
}

.row.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.col.no-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

div:focus {
  outline: none;  
}

input {
  color: #183041;
}

button {
  font-family: 'Muli', sans-serif;
  transition: all .2s ease-in-out;
}

button:focus {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

#root {
  min-height: 100vh;
}

#page {
  /*position: relative;*/
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.offcanvas #page {
  overflow: hidden;
  position: absolute;
}

h1, h2, h6, figure {
  color: #183041;
  margin: 0 0 20px 0;
  font-family: 'Danley', serif;
}

h2, h3, h4, h5 {
  color: rgba(24, 48, 65, 0.8);
}

h3 {
  font-weight: 500;
  font-style: normal;
  font-family: 'Danley', serif;
  letter-spacing: -0.01em;
  line-height: 120%;
  font-size: 24px;
}

h4 {
  margin: 0 0 20px 0;
  font-family: 'Muli', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

h5 {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;

  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

h5.lower {
  text-transform: none;
}


p {
  font-size: 14px;
  letter-spacing: -0.01em;
  line-height: 140%;
  color: rgba(24, 48, 65, 0.8);
}

p.full-color {
  color: rgb(24, 48, 65);
}

p.invalid {
  color: #F01212;
}

.lnd-footer {
  width: 100%;
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
}

.modal-container,
.survey-modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.60);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  transition: opacity 1s ease-in;
}

.survey-modal-container {
  display: block;
  overflow-y: scroll;
}

.survey-modal-container > .signin-modal-body {
  margin-left: auto;
  margin-right: auto;
}

.modal-container.dark {
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-body {
  background-color: #FFF;
}

.modal-title {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  
  color: #465967;
}

.modal-container.transparent {
  background-color: rgba(255,255,255, 0.9);
}

.modal-container.transparent > .modal-dialog {
  width: 330px;
}

.modal-container.transparent > .modal-dialog,
.interview-question-modal {
  width: 357px;
  min-height: 200px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #DFE0EB;
  padding: 16px 20px;
  position: relative;
  pointer-events: auto !important;
  box-shadow: 0px 0px 15px rgba(85, 85, 85, 0.15);
}

.interview-question-modal {
  /* min-height: 400px; */
  width: 460px;
  max-width: 95vw;
  padding-top: 24px;
  padding-bottom: 24px;
}

.modal-container.light,
.survey-modal-container {
  background-color: #fff;
}

.alert-dialog-medium {
  max-height: 600px;
  width: 500px;
  border-radius: 10px;
  background-color: #FFFFFF;
  padding: 20px;
}

.alert-dialog-small {
  width: 313px;
  border-radius: 10px;
  background-color: #FFFFFF;
  padding: 20px;
}

.dialog-title-text {
  margin-top: 20px;
  font-size: 28px;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
}

.alert-fixed-bottom {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10000;
}

@media screen and (max-width: 767px) {
  .alert-fixed-bottom {
    bottom: 60px;
  }
  #countdown-alert {
    font-size: 14px;
    height: 39px
  }
}

.dialog-body-text {
  margin-top: 20px;
  font-size: 24px;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  color: #000000;
  flex: 1;
}

.dialog-btn-ok {
  width: 198px;
  height: 40px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
  font-size: 14px;
}

.dialog-body {
  display: flex;
  flex-direction: column;
  height: 90%;
}

.nav-logo {
  height: 35px;
  margin-top: 24px;
  padding-left: 15px;
}

.spinner-grow {
  margin-bottom: 6px;
}

.back-arrow {
  cursor: pointer;
}

.back-arrow:hover {
  opacity: 0.5;
}

hr {
  border-bottom: 1px solid rgba(255,255,255,0.05);
}

.no-cloud {
  background: #F7F8FC;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.info-icon {
  padding: 4px;
  cursor: pointer;
}

.info-icon:hover {
  opacity: 0.5;
}

.btn-add {
  background: #007ced;
  color: #fff;
  border-radius: 25px;
  opacity: 1 !important;
}

.btn-add:hover {
  color: #cce7ff;
}

.span-link {
  font-weight: bold;
  color: #007ced;
  cursor: pointer; 
}
.order-prediction-subheader {
  font-size: 14px;
}

.input-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 515px;
}

.input-wrapper.account-modal {
  margin-top: -8px !important;
}

.input-wrapper > *,
.modal-input-container  > * {
  display: block;
  text-align: left;
}

.modal-input-container {
  margin: 4px;
}

.modal-input-container.full {
  width: 100%;
  max-width: 415px;
}

.modal-input,
.stripe-input {
  background: #EDEFF2;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  padding-left: 8px;
  color: rgba(24, 48, 65, 0.8);
  width: 200px;
  height: 40px;
}

.modal-label {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;

  color: rgba(24, 48, 65, 0.8);
}

.input-wrapper > input,
.modal-input-container > input,
.modal-input-container > textarea,
.modal-input-container > select {
  width: 200px;
  max-width: 100%;
  height: 40px;
  border-radius: 8px;
}

.input-wrapper > .invalid-input,
.modal-input-container > .invalid-input {
  border: 1px solid red;
}

.sd-modal-footer {
  margin-top: 20px;
}

#countdown-alert {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  text-align: center;
  color: #465967;
  font-weight: bold;
}

#countdown-alert.countdown-space-top {
  top: 10px;
}

.padding-top-delivery {
  padding-top: 39px;
}
.padding-top-delivery-lg {
  padding-top: 49px;
}
.padding-top-delivery-sm {
  padding-top: 36px;
}
.position-top-delivery {
  top: 39px;
}

.covid-alert {
  background-color: #FFD8BC;
  text-align: left;
  font-size: 14px;
  padding: 12px;
  display: flex;
  width: 304px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
}

.covid-alert > .checkbox-container {
  margin-right: 12px;
  margin-top: auto;
  margin-bottom: auto;
}

.checkbox {
  background-color: #fff;
  height: 25px !important;
  width: 25px !important;
  border: 2px solid #465967;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
}

.error-message-container {
  margin-top: -20px;
  padding-bottom: 20px;
}

.error-message {
  color: #F01212;
  font-size: 14px;
  line-height: 140%;
}