.header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 99999;
    position: relative;
}

.header-btn-container {
    min-width: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding-right: 15px; */
}

.header-btn {
    height: 54px;
    min-width: 54px;
    margin-top: 20px;
    background-color: transparent;
    border-radius: 6px;
    box-sizing: border-box;
    border: none;
    color: #183041;
    padding-right: 15px;
    cursor: pointer;
}

.btn-green {
    height: 54px;
    width: 137px;
    margin-top: 20px;
    background-color: #49CB93;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 16px;
    font-family: "Muli", sans-serif;
    border: none;
    color: #fff;
    cursor: pointer;
}

.btn-green:hover {
    background-color: #31af79;
    color: #fff;
}

.landing-header {
    z-index: 999999;
}

.header-btn:hover {
    text-decoration: underline;
    transition: text-decoration .3s ease-in;
}

.header-btn:active {
    transform: scale(.95);
}