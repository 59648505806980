.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-center {
    justify-content: center;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}

.flex-row-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-space-around {
    justify-content: space-around;
}

.flex-center-container {
    display: flex;
    justify-content: center;
}