.order {
    background-color: #fff;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    border-radius: 8px;
}

.order-product-thumbnail {
    max-height: 60px;
}

.order-label {
    font-size: 14px;
    color: rgba(24, 48, 65, 0.8);
}

.order-row {
    border-top: 1px solid #DFE0EB;
    min-height: 55px;
}

.order-row.no-border {
    border-top: none;
}

.order-row.view-all {
    color: #1F90FE;
}

.order-row.view-all:hover {
    text-decoration: underline;
}

.order-row > .col-md-2 {
    cursor: default;
}

.order-row.top {
    border-top: none;
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
    cursor: default;
}

.order-product-details {
    padding-left: 4px;
}

#manifest-total {
    background: #fff;
    color: #183041;
    padding: 0 !important;
    text-align: left;
    border: 1px solid #F1F1F1;
    padding-bottom: 8px !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 10px;
}

#manifest-total > hr {
    width: 90%;
}

#manifest-total-header {
    background: #183041;
    color: #fff;
    text-align: center;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    line-height: 160%;

    letter-spacing: -0.01em;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.manifest-total-row {
    padding-left: 8px;
    padding-right: 8px;
}

.edit > .order-product-details {
    justify-content: space-around;
}

.other-options-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.additional-products-row {
    width: 100%;
    flex-wrap: wrap;
}

.order-additional-product-container {
    min-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
}

.order-additional-product-container:hover,
.cluster-row.edit > .order-additional-product-container:hover {
    background: #fff;
    outline: 2px solid #1F90FE;
    z-index: 10
}

.other-options-text,
.order-additional-product {
    font-size: 12px;
    color: #465967;
    margin: auto 12px;
}

.order-additional-product {
    position: relative;
}

.order-additional-product > svg {
    position: absolute;
    top: 0;
    right: 0;
}

.order-additional-product > img {
    max-height: 115px;
}

.order-total > svg {
    padding-bottom: 2px;
    margin-left: 2px;
}

.cluster-row {
    flex-direction: row !important;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.cluster-row.edit {
   flex-direction: column !important; 
}

.additional-products {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
}

.cluster-row.edit {
    justify-content: center !important;
}

svg:hover > .info-circle {
    stroke: #1F90FE !important;
}
svg:hover > .info-dot,
svg:hover > rect,
svg:hover > path {
    fill: #1F90FE !important;
}

.order-product.edit,
.order-additional-product-container {
    padding-top: 12px;
    padding-bottom: 12px;
}

.cluster-row > .order-additional-product-container:hover,
.order-frame-container:hover,
.order-edit-row,
.order-product-container.open,
.order-additional-product-container.open,
.cluster-row.edit {
    background-color: #DCEEFF;
}

.order-product-container.past:hover {
    background: #fff;
    cursor: default;
}

.cluster-row > .order-additional-product-container:hover {
    outline: none;
}

.cluster-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    min-width: 242px;
}

.cluster-col.full {
    flex-direction: row;
}

.order-product-container {
    min-height: 60px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.order-product-container, svg {
    cursor: pointer;
}

.order-row > div,
.order--products-row > div,
.order-product-container,
.order-product-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.order-edit-row {
    position: relative;
    padding-right: 60px;
    padding-left: 60px;
    justify-content: center;
}

.order-row.btn-row,
.order-edit-row > .btn-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.order-edit-row > .btn-row {
    position: absolute;
    right: 12px;
    top: 50%;
    bottom: 50%;
}

.btn-row > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.dashboard-btn {
    margin-left: 4px;
    margin-right: 4px;
    height: 43px;
    border-radius: 50px;
    font-size: 14px;
    padding-left: 12px;
    min-width: 95px;
    padding-right: 12px;
    cursor: pointer;
    font-weight: bold;
}
.dashboard-btn.cta {
    font-size: 18px;
    height: 51px;
    min-width: 141px;
}
#complete-order-btn {
    width: 196px;
}
.dashboard-submit {
    background: #1F90FE;
    border: #1F90FE 1px solid;
    color: #fff;
}
.dashboard-save {
    background: #49CB93;
    border: #49CB93 1px solid;
    color: #fff;
}
.dashboard-cancel-btn {
    background: #F01212;
    border: #F01212 1px solid;
    color: #fff;
}

.dashboard-cancel-btn:hover {
    background: #DB1414;
    border: #DB1414 1px solid;
}

.dashboard-save:hover {
    background: #37B67F;
    border: #37B67F 1px solid;
}

.dashboard-submit.white {
    background: #FFF;
    border: 2px solid #DCDCDC;
    color: rgba(24, 48, 65, 0.8);;
}
.dashboard-submit.white:hover {
    background: #FFF;
    border: 2px solid #1F90FE;
}
.dashboard-submit:hover {
    background: #187AD9;
    border: #187AD9 1px solid;
}
.dashboard-cancel-link {
    border: none;
    font-size: 12px;
    color: #187AD9;
    cursor: pointer;
    background-color: transparent;
}
.dashboard-cancel-link:hover {
    text-decoration: underline;
}
.order-products-row {
    display: flex;
    justify-content: left;
    height: 100%;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.order-product {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 14px;
    padding-left: 4px;
    padding-right: 4px;
}

.order-frame-container {
    width: 400px;
    max-width: 95%;
}

.order-product-frame {
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding: 4px;
}

.order-product.historical > svg {
    float: right;
    margin-left: 4px;
    margin-top: 2px;
}

.order-edit-img {
    height: 100px;
}
.order-edit-img.additional {
    height: 60px;
}

.add-products-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
}

.add-products-btn-container.stacked {
    flex-direction: column;
}

.add-products-cluster-img {
    text-align: center;
}

.add-products-cluster-img > img {
    max-height: 75px;
    width: auto;
}

.order-add-products-btn {
    text-align: center;
    border: 2px solid #DCDCDC;
    box-sizing: border-box;
    border-radius: 50px;
    height: 43px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 140px;
    margin-left: auto;
    margin-right: auto;
}

.order-add-products-btn:hover {
    border: 2px solid #1F90FE;
}

.dashboard-btn-text {
    font-size: 12px;
    line-height: 21px;
    font-weight: bold;
    color: #183041;
    opacity: 0.8;
}

#blue-plus {
    color: #1F90FE;
}

.order-x-icon {
    position: absolute;
    right: 15px;
    opacity: 0.4;
    cursor: pointer;
    z-index: 9998;
}
.order-x-icon.raised {
    top: 16px;
}

.modal-dialog > .order-x-icon {
    z-index: 9000;
}

.order-x-icon:hover {
    opacity: 1;
}

.cluster-progress-bar {
    width: 150px;
    height: 25px;
    border-radius: 50px;
    border: 1px solid #DCDCDC;
    margin: 12px auto 24px auto;
    position: relative;
    cursor: default;
}

.cluster-progress-bar.full {
    margin-left: 0;
}

.full > .cluster-name-container {
    margin-left: 12px;
}
.cluster-name-container {
    text-align: center;
}

.cluster-percentage {
    z-index: 2;
    text-align: center;
    position: relative;
}

.cluster-progress {
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    position: absolute;
    z-index: 1;
}

.cluster-progress.rounded {
    border-radius: 50px;
}
.mobile-edit-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 8px;
    min-width: 88px;
}
.products-option-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.products-option-container > .prediction-only-option {
    margin: auto;
}

.subtotal-text, .total-text {
    text-align: right;
    color: #9FA2B4;
    font-size: 14px;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    line-height: 15px;
}

.total-text, .total-price {
    font-size: 14px;
}

.total-price, .subtotal-price {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    line-height: 15px;
    color: #465967;
}

.mobile-totals {
    margin-top: 12px;
    margin-bottom: 12px;
}

.mobile-totals > * {
    margin-top: 4px;
    margin-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.product-mobile-edit {
    color: #007bff !important;
}

.info-container {
    margin-left: 8px;
}

.border-bottom {
    border-bottom: 2px solid #fff !important;
}

@media screen and (max-width: 767px) {
    .subtotal-text,
    .total-text,
    .order-product,
    .total-price,
    .subtotal-price {
        font-size: 12px;
    }
    .order-product-thumbnail {
        max-height: 50px;;
    }
    .order-frame-container {
        width: 343px;
    }
    .mobile-edit-container {
        min-width: 75px;
    }
    .order-edit-row {
        padding: 0 !important;
    }
    .products-option-container {
        flex-direction: column;
        justify-content: center;
    }

    .products-option-container {
        margin-left: auto;
        margin-right: auto;
    }
    .order-product-container {
        margin: 0 auto;
        width: 100%;
    }
    .desktop {
        display: none !important;
    }

    .order-product.edit {
        width: 100%;
        justify-content: center;
    }

    .order-row > .col-md-2 {
        cursor: pointer;
    }

    .cluster-row.full {
        width: 260px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .cluster-col.full {
        justify-content: left;
        direction: flex;
    }
}