.signin-modal-body,
.beta-modal-body {
    min-height: 360px;
    width: 540px;
    max-width: 95vw;
    border-radius: 12px;
    padding: 32px;
    overflow: visible;
    position: relative;
  }

  .beta-modal-body {
    padding-bottom: 72px;
    position: absolute;
    top: 60px;
  }
  
  .signin-modal-section-header,
  .loading-modal-section-header {
      font-family: 'Muli', sans-serif;
      font-weight: normal;
      font-size: 12px;
      margin-bottom: 12px;
  }
  
  .signin-modal-input {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 3px;
    height: 40px;
    width: 240px;
    margin-bottom: 8px;
    padding-left: 8px;
  }
  
  .signin-modal-landing-parachute,
  .loading-modal-landing-parachute {
    height: 260px;
    top: 20px;
    right: 0px;
  }
  
  .signin-modal-cloud-dark,
  .signin-modal-cloud-light,
  .signin-modal-landing-parachute,
  .loading-modal-cloud-dark,
  .loading-modal-cloud-light,
  .loading-modal-landing-parachute {
    position: absolute;
  }
  
  .signin-modal-cloud-dark,
  .loading-modal-cloud-dark {
      right: 170px;
      top: 20px;
  }
  
  .signin-modal-cloud-light,
  .loading-modal-cloud-light {
    right: 40px;
    top: 260px;
  }
  
  .signin-modal-button,
  .prediction-modal-button {
      bottom: 0 !important;
      width: 100% !important;
  }

  .signin-modal-link {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */
    letter-spacing: -0.01em;
    text-decoration-line: underline;
  
    color: #183041;
  
    margin-top: 12px;
    cursor: pointer;
  }
  
  .signin-modal-link:hover {
    opacity: 0.8;
  }
  
  .signin-modal-tab {
    top: -32px !important;
    right: -32px !important;
  }

  .signin-modal-subtext {
    font-size: 12px;
    width: 343px;
    padding: 0 8px;
  }

  .signin-modal-alert {
    width: 343px;
    max-width: 100%;
    margin-top: 8px;
    height: auto;
    font-size: 14px;
    padding-top: 4px;
  }

  .signin-main-header {
    margin: 24px 0;
    font-family: 'Danley', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #183041;
  }

  .signin-radio-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 240px;
    margin-top: 12px;
  }

  .signin-subtext {
    font-size: 14px;
    margin: 24px 0;
  }

  .signin-radio-container {
    display: flex;
    justify-content: flex-start;
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    .loading-modal-cloud-dark {
      top: 120px;
      right: 120px;
    }
    .signin-modal-subtext {
      width: 240px;
    }
    .loading-modal-cloud-light {
      top: 60px;
      right: 0;
    }
    .loading-modal-landing-parachute {
      top: 60px;
      right: 12px;
      height: 160px;
    }
    .signin-modal-cloud-dark {
      height: 20px;
      right: 68px;
      top: 24px;
    }
    .signin-modal-cloud-light {
      top: 72px;
      height: 20px;
      right: 0;
    }
    .signin-modal-landing-parachute {
      top: 0px;
      right: 0px;
      height: 92px;
      z-index: 4;
    }
  }