.alert-wrapper {
    width: 90%;
    height: 100px;
    margin-top: 100px;
}

.green.summary-subtotal-price {
    padding-left: 8px !important;
}

.text-alert {
    background-color: #87A061;
    font-weight: bold;
}

.alert-success .alert-link {
    color: #FFFFFF;
}

.open-popup-link {
    text-decoration: underline;
    border: 0px none;
}

.open-popup-link:hover, .open-popup-link:active, .open-popup-link:focus {
    text-decoration: underline;
    border: 0px none;
    color: #FFFFFF;
}

.translucent-background {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.80);
}

.change-phone-dialog {
    display: flex;
    padding: 10px;
    height: 600px;
    width: 500px;
    background-color: #FFFFFF;
    align-items: center;
    flex-direction: column;
    z-index: 99;
    position: relative;
}

.phone-icon {
    margin-top: 30px;
}

.change-phone-form {
    width: 100%;
    padding-left: 70px;
    padding-right: 70px;
}

.dialog-title {
    color: #00ABC8;
    font-size: 32px;
    font-family: 'Muli', sans-serif;
    margin-top: 10px;
}

.field-wrapper {
    width: 100%;
    margin-top: 30px;
    font-weight: bold;
}

.all-screen-dialog {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9999;
    justify-content: center;
    align-items: center;
}

.close-alert-button {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    text-decoration: none;
    text-align: center;
    padding: 0 0 18px 10px;
    color: #FFFFFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
    outline: none;
}

.close-button {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #000000;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.close-button:hover, .close-button:focus, .close-alert-button:hover, .close-alert-button:focus{
    opacity: 1;
    filter: alpha(opacity=100);
    outline: none;
}

.close-button:active, .close-alert-button:active {
    margin-top: 1px;
}

@media screen and (max-width: 768px) {
    .alert-wrapper {
        width: 90%;
        height: 120px;
    }
  }
  
@media screen and (max-width: 600px) {
    .alert-wrapper {
        width: 90%;
        height: 150px;
    }

    .change-phone-dialog {
        width: 350px;
    }

    .change-phone-form {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media screen and (max-width: 400px) {
    .change-phone-dialog {
        width: 300px;
    }

    .change-phone-form {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.order-complete-alert {
    color: #fff;
    background-color: #655DC6;
    border-radius: 4px;
}

#order-complete-body {
    min-height: 100vh;
}

#completeImage {
    height: 120px;
}