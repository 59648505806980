#menu-container-desktop {
    min-height: 100vh;
    height: 100%;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    width: 240px;
    position: absolute;
    top: 0;
    z-index: 1;
}

#menu-container {
    background-color: #fff;
    border-bottom: 1px solid #dcdcdc;
    height: 100%;
    text-align: center;
}

#menu-container-mobile {
    display: none;
    width: 100%;
    max-width: 1110px;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

#menu-hamburger-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#menu-logo-desktop {
    text-align: center;
    width: 180px;
    margin: 32px auto;
}

#menu-logo-mobile {
    display: block;
    width: auto;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
}

#menu-hamburger {
    color: #4E5E6F;
    font-size: 24px;
}

#menu-hamburger:hover {
    cursor: pointer;
    color: #1F90FE;
}

.menu-icon {
    width: 80px;
    height: 28px;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 8px;
}

.menu-nav-row {
    height: 40px;
    padding-top: 6px;
    color: #A4A6B3;
    cursor: pointer;
    margin-left: 0px;
    margin-right: 0px;
}

.menu-nav-row:hover, .menu-nav-row.selected, .menu-nav-row.selected:hover {
    background-color: rgba(159,162,180,0.08);
    color: #7ABDFF;
}

.menu-nav-row.selected {
    color: #7ABDFF;
}
.menu-nav-row.selected:hover {
    background: rgba(159,162,180,0.08);

}


.menu-nav-link {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    letter-spacing: 0.2px;
    padding-top: 4px;
    /* sidebar / gray */
    color: #566575 !important;
    text-align:left; 
}

.menu-nav-link:hover {
    color: #1F90FE !important;
}

#dashboard-body-container {
    min-height: 100vh;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.menu-mobile-list {
    position: absolute;
    background-color: #fff;
    padding-bottom : 12px;
    right: 0px;
    width: 240px;
    border: 1px solid #dcdcdc;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 999;
}

.menu-mobile-list > * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.menu-mobile-list > .menu-icon {
    padding-left: 12px;
}

#menu-home-icon {
    background-image: url('../img/menu_icons/home_gray.svg');
}

.menu-nav-row:hover > #menu-home-icon {
    background-image: url('../img/menu_icons/home_selected.svg');
}

#menu-parachute-icon {
    background-image: url('../img/menu_icons/parachute_gray.svg');
}

.menu-nav-row:hover > #menu-parachute-icon {
    background-image: url('../img/menu_icons/parachute_selected.svg');
}
.menu-nav-row:hover > #menu-feedback-icon {
    color: #7ABDFF;
}

#menu-edit-icon {
    background-image: url('../img/menu_icons/edit_gray.svg');
}

#menu-feedback-icon {
    font-weight: bold;
    color: #183041;
}

.menu-nav-row:hover > #menu-edit-icon {
    background-image: url('../img/menu_icons/edit_selected.svg');
}

#menu-logout-icon {
    background-image: url('../img/menu_icons/logout_gray.svg');
}

.menu-nav-row:hover > #menu-logout-icon {
    background-image: url('../img/menu_icons/logout_selected.svg');
}

.menu-mobile-list > a {
    color: #fff !important;
    padding-left: 12px;
    opacity: 0.6;
    cursor: pointer;
}

.menu-mobile-list > a:hover {
    opacity: 1;
}

.dashboard-orders {
    width: 100%;
    max-width: 1110px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
}

.dashboard-orders.add-products > div > .order.container {
    box-shadow: 0px 0px 15px rgba(85, 85, 85, 0.15);
}
.dashboard-orders.add-products {
    margin-top: 60px;
    margin-bottom: 60px;
}

.dashboard-sub-header {
    text-align: left;
}

.dashboard-order {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.dashboard-order-group {
    margin: 0 0 4px -20px;
    width: 313px;
    border-radius: 8px;
    min-height: 100px;
    padding-right: 8px;
}

.dashboard-order-container {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    width: 313px;
    border-radius: 8px;
    padding: 20px;
}

.dashboard-edit-order {
    cursor: pointer;
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */
    letter-spacing: -0.01em;
    text-decoration-line: underline !important;
    color: #1F90FE !important;
    
    opacity: 0.8;
}

.dashboard-edit-order:hover {
    color: #183041 !important;
}

.dashboard-edit-order:active {
    opacity: 0.5;
}

.dashboard-headers-container {
    margin: 32px 0;
    text-align: left;
    padding: 0 15px;
}

.dashboard-headers-container > * {
    max-width: 1110px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.dashboard-main-header {
    font-family: 'Danley', serif;
    font-size: 28px;
    margin-bottom: 8px !important;
}

.dashboard-button-container {
    display: flex;
    justify-content: space-around;
    margin-top: 32px;
}

.dashboard-button-container > button {
    min-height: 48px;
    min-width: 120px;
    font-size: 16px;
    border-radius: 6px;
}

.dashboard-order-total {
    display: flex;
    justify-content: space-between;
}

.dashboard-order-total-text {
    padding-top: 6px;
}

.dashboard-order-total-price {
    font-size: 24px;
}

.dashboard-title {
    background-color: rgba(24,48,65, 0.2);
    padding-left: 50px; 
    font-family: 'Muli', sans-serif;    
    margin-bottom: -20px;
}

.dashboard-title > h2 {
    color: rgba(24,48,65, 0.6) !important;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
}

.dashboard-form-container {
    width: 100%;
}

.dashboard-form-row {
    margin-left: 30px;
    margin-right: 8px;
}

.dashboard-section-container {
    padding: 10px 20px 20px;
    background: #FFFFFF;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    border-radius: 8px;
    color: #183041;
    width: 343px;
    max-width: 100%;
    height: fit-content;
    margin-top: 12px;
    margin-bottom: 30px;
}

.dashboard-section-label {
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: 0;
}

.dashboard-section-data {
    padding-left: 8px;
}

.dashboard-section-body.indented {
    padding-left: 8px;
    margin-bottom: 12px;
}

.dashboard-section-group.w50 {
    width: 50%;
}

.dashboard-section-group.w100 {
    width: 100%;
}

.dashboard-section-group.w100 > .stripe-input,
.dashboard-section-group.w100 > div > input {
    width: 95%;
}

.dashboard-edit-icon {
    opacity: 0.6;
    cursor: pointer;
    margin-top: -8px;
}

.dashboard-edit-icon:hover {
    opacity: 1;
}

.dashboard-section-input {
    border-radius: 4px;
    padding-left: 4px;
    margin-left: 8px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    width: 90%;
    height: 39px;
}

.account-header-container {
    margin-left: 30px;
    margin-top: 43px;
}

.account-header-container >img {
    height: 23px;
    width: auto;
    margin-top: auto;
    margin-bottom: 12px;
}

#account-header {
    font-size: 28px;
    margin-top: 12px;
    margin-bottom: 0 !important;
    margin-left: 12px;
    display: inline-block;
}

#dashboard-checkbox {
    margin-left: 8px;
    margin-right: 8px;
}

.account-section-header-container {
    width: 343px;
}
.account-section-header-container > h4 {
    margin-bottom: 0 !important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
}

input.invalid,
/* label.invalid, */
select.invalid,
input.invalid::placeholder,
select.invalid::placeholder {
    border-color: #dc3545 !important;
    background-color: #FFF1F1;
}

.order-left-align-container {
    width: 313px;
    margin-left: auto;
    margin-right: auto;
}

.dashboard-totals-container {
    width: 312px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.account-sections-container > div {
    margin-right: auto;
    margin-left: 30px;
}
.account-sections-container {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashboard-section-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.account-section-subheader {
    font-weight: bold;
    color: rgba(24, 48, 65, 0.8);
    margin-bottom: 10px;
}

.dashboard-section-header-row > div {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
}

.account-section-edit {
    color: #1F90FE;
    cursor: pointer;
    font-size: 14px;
}

#pause-icon {
    margin-bottom: 4px;
    margin-right: 4px;
}

.account-section-edit:hover {
    text-decoration: underline;
}

#menu-mobile-header {
    display: flex;
    justify-content: space-between;
}

@media screen and (min-width: 992px) {
    .dashboard-title {
        display: none;
    }
    #dashboard-body-container {
        margin-left: 240px;
    }
}

@media screen and (max-width: 991px) {
    .account-sections-container > div {
        margin-left: auto;
        margin-right: auto;
    }
    .account-header-container {
        width: 343px;
        margin-left: auto;
        margin-right: auto;
    }
    .dashboard-section-input {
        width: 100%;
    }
    #menu-container {
        width: 100%;
        position: relative;
    }
    #menu-container-desktop {
        display: none;
    }
    
    #menu-container-mobile {
        display: block;
    }


    #dashboard-body-container {
        margin-left: 0;
    }
    .dashboard-order {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .dashboard-account-row {
        flex-direction: column !important;
    }
    .dashboard-section-group.w50,
    .dashboard-section-group.w100 > .stripe-input,
    .dashboard-section-group.w100 > div > input {
        width: 100%;
    }
}
.loading-icon {
    position: absolute;
    width: 6em;
    height: 6em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: visible;
  }
  .loading-icon :hover {
    cursor: wait;
  }
  .loading-icon .loading-icon__animated-stroke {
    stroke-dasharray: 627.3;
    animation: loading-icon-stroke 2.5s ease-in-out infinite, loading-icon-spin 2s linear infinite;
  }
  .loading-icon .loading-icon__static-stroke-section {
    stroke-dasharray: 50 577.3;
    stroke-dashoffset: 25;
    animation: loading-icon-spin 2s linear infinite;
  }
  
  @keyframes loading-icon-stroke {
    0% {
      stroke-dashoffset: 602.3;
    }
    100% {
      stroke-dashoffset: -602.3;
    }
  }
  @keyframes loading-icon-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes grow {
    0% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
    50% {
      transform: scale(1.2);
      -webkit-transform: scale(1.2);
    }
    100% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
  .grow {
    animation: grow 2s ease-in-out infinite;
  }